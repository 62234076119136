import { useQuery } from 'react-query'

import { useMainMenuAction } from '@hooks/mainMenu/useMainMenuAction'

export function useMenu() {
  const mainMenuClient = useMainMenuAction()
  const { data = [] } = useQuery(['menus'], () => mainMenuClient.mainMenus(), {
    staleTime: Infinity,
  })

  return { data }
}
