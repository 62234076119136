import { plainToInstance } from 'class-transformer'

import { MAIN_MENUS } from '@client/collections/MainMenus/schemas/mainMenus'
import { gqlApiInstance } from '@client/init'
import { MainMenuType } from '@models/mainMenu/MainMenuType'

export function useMainMenuAction() {
  async function mainMenus(): Promise<MainMenuType[]> {
    const res = await gqlApiInstance.request(MAIN_MENUS)
    return plainToInstance(MainMenuType, res.mainMenus as [])
  }

  return {
    mainMenus,
  }
}
