import { Expose, Transform } from 'class-transformer'
import React from 'react'

import { MenuTypeEnum } from '@interfaces/MenuTypeEnum'
import { ArticleIcon } from '@components/icons/ArticleIcon'
import { BookOutlineIcon } from '@components/icons/BookOutlineIcon'
import { CartoonIcon } from '@components/icons/CartoonIcon'
import { EBookIcon } from '@components/icons/EBookIcon'
import { LikeIcon } from '@components/icons/LikeIcon'
import { StarIcon } from '@components/icons/StarIcon'

export class MainMenuType {
  @Expose()
  id: number

  @Expose()
  name: string

  @Expose()
  menuType: MenuTypeEnum

  @Expose()
  @Transform(({ obj }) => {
    return [
      MenuTypeEnum.EBOOK,
      MenuTypeEnum.MANGA,
      MenuTypeEnum.NOVEL,
      MenuTypeEnum.TOP_CHART_BOOK,
      MenuTypeEnum.TOP_CHART_CHARACTER,
    ].includes(obj.menuType)
  })
  subMenu: boolean

  @Expose()
  @Transform(({ obj }) => {
    if (obj.menuType === MenuTypeEnum.EBOOK) {
      return '/category-ebook'
    }
    if (obj.menuType === MenuTypeEnum.MANGA) {
      return '/category'
    }
    if (obj.menuType === MenuTypeEnum.NOVEL) {
      return '/category'
    }
    if (obj.menuType === MenuTypeEnum.TOP_CHART_BOOK) {
      return '/top-view'
    }
    if (obj.menuType === MenuTypeEnum.TOP_CHART_CHARACTER) {
      return '/character'
    }
    if (obj.menuType === MenuTypeEnum.ARTICLE) {
      return '/article'
    }
    return '/'
  })
  pathname: string

  @Expose()
  @Transform(({ obj }) => {
    return obj.menuType === MenuTypeEnum.EBOOK ? (
      <EBookIcon />
    ) : obj.menuType === MenuTypeEnum.MANGA ? (
      <CartoonIcon />
    ) : obj.menuType === MenuTypeEnum.NOVEL ? (
      <BookOutlineIcon />
    ) : obj.menuType === MenuTypeEnum.TOP_CHART_BOOK ? (
      <LikeIcon />
    ) : obj.menuType === MenuTypeEnum.TOP_CHART_CHARACTER ? (
      <StarIcon />
    ) : obj.menuType === MenuTypeEnum.ARTICLE ? (
      <ArticleIcon />
    ) : null
  })
  icon: React.ReactNode
}
